import { Button } from "components/Button";

type SelectionButtonProps = {
  text?: string;
  selected?: boolean;
  onClick: () => void;
};

const SelectionButton: React.FC<SelectionButtonProps> = ({
  text,
  selected,
  onClick,
}) => {
  return (
    <Button
      text={text}
      buttonVariant={selected ? "primary" : "outlineLink"}
      onClick={onClick}
    />
  );
};

export default SelectionButton;
