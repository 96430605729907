import { useSelector } from "@xstate/react";
import { Checkbox, Select, ZTabs } from "@zakeke/zakeke-ui-widgets";
import { Tab, TabList, TabPanel } from "react-tabs";

import useOptimizeActor from "../hooks/useOptimizeActor";

const ExportTab = () => {
  const optimizeActor = useOptimizeActor();
  const glbExportData = useSelector(
    optimizeActor,
    (snap) => snap.context.formData?.export?.[0]?.format?.glb,
  );

  return (
    <div className="tw-bg-gray-50 tw-p-8">
      <div className="tw-text-base tw-font-bold">Export</div>
      <div className="tw-mt-2 tw-text-base">
        Specific And Customization of Export Options
      </div>

      <Select className="tw-mt-3 tw-max-w-36" name="SelectPreset" disabled>
        <option value="glb">GLB</option>
      </Select>

      <div className="tw-text-base tw-font-bold tw-mt-5">
        Geometry Compression
      </div>
      <div className="tw-mt-3 tw-text-sm">
        Desired geometry compression (currently available only for (glTF/GLB
        exports))
      </div>
      <Select
        className="tw-mt-3 tw-max-w-lg"
        value={glbExportData?.geometryCompression ?? "none"}
        onChange={(value) => {
          const newValue = value === "none" ? undefined : value;
          optimizeActor.send({
            type: "update.formData",
            payload: {
              accessPath: "export[0].format.glb.geometryCompression",
              value: newValue,
            },
          });
        }}
      >
        <option value="none">none</option>
        <option value="draco">draco</option>
        <option value="dracoLossy">dracoLossy</option>
        <option value="meshQuantization">meshQuantization</option>
      </Select>

      {/* section */}
      <div className="tw-mt-5">
        <ZTabs>
          <TabList onChange={(v) => console.log("TAB VALUE", v)}>
            <Tab>
              <span className="tw-text-sm">Maximum Texture Map Resolution</span>
            </Tab>
            <Tab>
              <span className="tw-text-sm">Texture Map Format</span>
            </Tab>
          </TabList>
          <TabPanel>
            <div className="tw-px-5 tw-pb-8 tw-bg-white">
              <Checkbox
                className="tw-mt-5"
                label="Maximum Texture Map Resolution"
                onChange={(value) => {
                  const obj = value ? {} : undefined;
                  optimizeActor.send({
                    type: "update.formData",
                    payload: {
                      accessPath:
                        "export[0].format.glb.pbrMaterial.maxTextureResolution",
                      value: obj,
                    },
                  });
                }}
                checked={glbExportData?.pbrMaterial?.maxTextureResolution}
                caption="Maximum resolution for exported Texture Maps"
              />

              <div className="tw-text-base tw-font-bold tw-mt-5">
                Default Maximum Texture Map Resolution
              </div>
              <div className="tw-mt-3 tw-text-sm">
                Default Maximum Resolution for exported Texture Maps
              </div>

              <input
                className="tw-w-32 tw-mt-3 tw-accent-[#F46200] tw-self-start tw-p-2.5 tw-border tw-border-slate-300 tw-border-solid tw-rounded"
                type="number"
                disabled={!glbExportData?.pbrMaterial?.maxTextureResolution}
                onChange={(event) => {
                  optimizeActor.send({
                    type: "update.formData",
                    payload: {
                      accessPath:
                        "export[0].format.glb.pbrMaterial.maxTextureResolution.default",
                      value: event.target.value,
                    },
                  });
                }}
                defaultValue={4764}
                min={1}
                max={5000}
              />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="tw-px-5 tw-pb-8 tw-bg-white">
              <Checkbox
                className="tw-mt-5"
                label="Texture Map Format"
                onChange={(checked) => {
                  const newValue = checked ? {} : undefined;
                  optimizeActor.send({
                    type: "update.formData",
                    payload: {
                      accessPath:
                        "export[0].format.glb.pbrMaterial.textureFormat",
                      value: newValue,
                    },
                  });
                }}
                checked={glbExportData?.pbrMaterial?.textureFormat}
                caption="Format for exported Texture Maps"
              />

              <div className="tw-text-base tw-font-bold tw-mt-5">
                Default Texture Map Format
              </div>
              <div className="tw-mt-3 tw-text-sm">
                Default format for exported Texture Maps
              </div>

              <Select
                className="tw-mt-3 tw-max-w-lg"
                name="SelectPreset"
                disabled={!glbExportData?.pbrMaterial?.textureFormat}
                value={glbExportData?.pbrMaterial?.textureFormat ?? "auto"}
                onChange={(value) => {
                  optimizeActor.send({
                    type: "update.formData",
                    payload: {
                      accessPath:
                        "export[0].format.glb.pbrMaterial.textureFormat",
                      value,
                    },
                  });
                }}
              >
                <option value="auto">auto</option>
                <option value="jpg">jpg</option>
                <option value="png">png</option>
                <option value="png8">png8</option>
                <option value="webp">webp</option>
                <option value="ktx-basis-etc1s">ktx-basis-etc1s</option>
                <option value="ktx-basis-uastc">ktx-basis-uastc</option>
              </Select>
            </div>
          </TabPanel>
        </ZTabs>
      </div>
    </div>
  );
};

export default ExportTab;
