import { useSelector } from "@xstate/react";
import { FormControl, Select } from "@zakeke/zakeke-ui-widgets";
import classNames from "classnames";

import icon3dedit from "@/svg/3dedit.svg?plain";
import iconExport from "@/svg/export.svg?plain";
import iconImport from "@/svg/import.svg?plain";
import iconMeshCulling from "@/svg/mesh-culling.svg?plain";
import iconModifier from "@/svg/modifier.svg?plain";
import iconOptimize from "@/svg/optimize.svg?plain";

import Ctas from "./components/Ctas";
import ExportTab from "./components/ExportTab";
import { Field } from "./components/Field";
import { OptimizeTab } from "./components/OptimizeTab";
import useOptimizeActor from "./hooks/useOptimizeActor";

const iconMap: Record<string, string> = {
  import: iconImport,
  "3dEdit": icon3dedit,
  meshCulling: iconMeshCulling,
  optimize: iconOptimize,
  outcomeModifier: iconModifier,
  exportArray: iconExport,
};

export default function Template() {
  const optimizeActor = useOptimizeActor();

  const rapidPipelineSchema = useSelector(
    optimizeActor,
    (snap) => snap.context.rapidPipelineSchema,
  );
  const parsedRapidPipelineSchema = useSelector(
    optimizeActor,
    (snap) => snap.context.parsedRapidPipelineSchema,
  );
  const activeConfigurationSection = useSelector(
    optimizeActor,
    (snap) => snap.context.activeConfigurationSection,
  );
  const presetsData = useSelector(
    optimizeActor,
    (snap) => snap.context.presetsData,
  );

  const currentPresetValue = useSelector(
    optimizeActor,
    (snap) => snap.context.currentPresetId,
  );

  rapidPipelineSchema &&
    console.log("rapidpipeline schema: ", rapidPipelineSchema);
  const formSettings = useSelector(
    optimizeActor,
    (snap) => snap.context.formData,
  );
  console.log("FORM SETTINGS", formSettings);

  return (
    <>
      {rapidPipelineSchema && (
        <div className="tw-flex tw-flex-col tw-justify-between h-full">
          <section className="tw-flex tw-gap-16 tw-p-10">
            <div className="tw-w-1/3 tw-flex tw-flex-col">
              <h4 className="tw-my-0">Select Optimization preset</h4>
              <p>
                Start by selecting your preferred settings or choosing a
                pre-configured preset tailored to a specific use case.
              </p>
              <ul className="tw-flex tw-flex-col tw-list-none tw-pl-0">
                {parsedRapidPipelineSchema.map((tab) => {
                  return (
                    <li
                      key={tab.id}
                      className={classNames(
                        "tw-grid tw-grid-cols-[1.5rem_1fr] tw-gap-1 tw-items-center tw-mb-1 tw-p-3 tw-font-bold tw-shadow hover:tw-cursor-pointer",
                        {
                          "tw-text-[#F46200] tw-shadow-orange-100":
                            tab.id === activeConfigurationSection,
                        },
                      )}
                      onClick={() =>
                        optimizeActor.send({
                          type: "newActiveConfigurationSection",
                          payload: tab.id,
                        })
                      }
                    >
                      <img
                        src={iconMap[tab.id]}
                        alt=""
                        className={classNames("icon", {
                          "tw-filter tw-brightness-0 tw-saturate-100 tw-invert-[58%] tw-sepia-[29%] tw-saturate-[7178%] tw-hue-rotate-[352deg] tw-brightness-[101%] tw-contrast-[95%]":
                            tab.id === activeConfigurationSection,
                        })}
                      />
                      {tab.name}
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="tw-w-2/3">
              <FormControl>
                <Select
                  name="SelectPreset"
                  onChange={(value) => {
                    if (value === "blank") {
                      optimizeActor.send({ type: "reset.preset" });
                    } else {
                      optimizeActor.send({
                        type: "select.preset",
                        payload: { presetId: Number(value) },
                      });
                    }
                  }}
                  value={currentPresetValue ?? "blank"}
                >
                  <option value="blank">---</option>
                  {presetsData.map((preset) => (
                    <option key={preset.id} value={preset.id}>
                      {preset.name}
                    </option>
                  ))}
                  <option disabled value="custom">
                    Custom
                  </option>
                </Select>
              </FormControl>
              {parsedRapidPipelineSchema.map((tab) => {
                if (activeConfigurationSection !== tab.id) {
                  return null;
                } else if (tab.name === "Export") {
                  return <ExportTab />;
                }

                if (tab.id === "optimize") {
                  return (
                    <OptimizeTab
                      key={tab.id}
                      tab={rapidPipelineSchema.properties.optimize}
                      materialOptimizationRef={
                        rapidPipelineSchema["$defs"]["__ref_177__"]
                      }
                    />
                  );
                }

                return (
                  <div key={tab.id} className="tw-bg-gray-50 tw-p-8">
                    <h4 className="tw-my-0">{tab.name}</h4>
                    <p>{tab.description}</p>
                    {tab.fields.map((field) => {
                      return <Field key={field.id} field={field} />;
                    })}
                  </div>
                );
              })}
            </div>
          </section>
          <Ctas />
        </div>
      )}
    </>
  );
}
