import _ from "lodash";
import { assign, setup } from "xstate";

import { detailActor } from "../assets/actors";
import { getOptimizationDataActor, optimizeActor } from "./actors";
import { RapidPipelineSchema, Tab } from "./types";

type UpdateFormDataPayload = {
  accessPath: string;
  value: any;
};

type Context = {
  rapidPipelineSchema: RapidPipelineSchema | null;
  parsedRapidPipelineSchema: Tab[] | [];
  activeConfigurationSection: string;
  formData?: Record<string, any>;
  presetsData: Record<string, any>[];
  currentPresetId: number | "custom" | null;
  rawmodelId: string | null;
  error: unknown;
  message: string | null;
  assetId: string | null;
};

type Events =
  | { type: "newActiveConfigurationSection"; payload: string }
  | { type: "update.formData"; payload: UpdateFormDataPayload }
  | { type: "select.preset"; payload: { presetId: number | null } }
  | { type: "reset.preset" }
  | { type: "optimize" };

type OptimizeInput = {
  assetId: string;
};

const machine = setup({
  types: {
    context: {} as Context,
    events: {} as Events,
    input: {} as OptimizeInput,
  },
  actors: {
    getInitialData: getOptimizationDataActor,
    optimize: optimizeActor,
    getAssetDetail: detailActor,
  },
  actions: {
    onOptimizeSuccess: () => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onOptimizeFail: (error: unknown) => {},
  },
}).createMachine({
  id: "optimizeMachine",
  context: ({ input }) => ({
    rapidPipelineSchema: null,
    parsedRapidPipelineSchema: [],
    activeConfigurationSection: "import",
    presetsData: [],
    formData: { export: [{ format: { glb: {} } }] },
    currentPresetId: null,
    rawmodelId: null,
    assetId: input.assetId,
    error: null,
    message: null,
  }),
  initial: "mounting",
  states: {
    idle: {
      on: {
        "update.formData": {
          actions: assign(({ event, context }) => {
            const clonedFormData = _.cloneDeep(context.formData ?? {});
            let value = event.payload.value;

            if (
              event.payload.accessPath === "modifier.filesize" &&
              Boolean(event.payload.value)
            ) {
              //TODO: find better way to fix it
              value = {
                maxMegabytesOnDisk: 5,
              };
            }

            _.set(clonedFormData, event.payload.accessPath, value);
            return {
              ...context,
              formData: clonedFormData,
              currentPresetId: "custom",
            };
          }),
        },
        "select.preset": {
          actions: assign({
            formData: ({ event, context }) =>
              //@ts-ignore
              context.presetsData.find((p) => p.id === event.payload.presetId)
                .config,
            currentPresetId: ({ event }) => event.payload.presetId,
          }),
        },
        "reset.preset": {
          actions: assign({
            formData: { export: [{ format: { glb: {} } }] },
            currentPresetId: null,
          }),
        },
        optimize: {
          target: "optimizing",
        },
      },
    },
    mounting: {
      invoke: {
        src: "getInitialData",
        onDone: {
          target: "loadingAsset",
          actions: assign({
            rapidPipelineSchema: ({ event }) => event.output.schema,
            parsedRapidPipelineSchema: ({ event }) => event.output.parsedSchema,
            presetsData: ({ event }) => event.output.presetsData,
          }),
        },
      },
    },
    loadingAsset: {
      invoke: {
        src: "getAssetDetail",
        input: ({ context }) => ({ id: context.assetId! }),
        onDone: {
          target: "idle",
          actions: assign({
            rawmodelId: ({ event }) =>
              String(event.output.models[0].rawModelId),
          }),
        },
        //@ts-ignore
        onError: {
          target: "idle",
          actions: "onOptimizeFail",
        },
      },
    },
    optimizing: {
      invoke: {
        src: "optimize",
        input: ({ context }) => ({
          formData: context.formData,
          rawmodelId: context.rawmodelId!,
        }),
        onDone: {
          target: "idle",
          actions: "onOptimizeSuccess",
        },
        //@ts-ignore
        onError: {
          target: "idle",
          actions: "onOptimizeFail",
        },
      },
    },
  },
  on: {
    newActiveConfigurationSection: {
      actions: assign({
        activeConfigurationSection: ({ event }) => event.payload,
      }),
    },
  },
});

export default machine;
