import classnames from "classnames";
import React from "react";
import { CSSProperties, ReactNode } from "react";

import { isScrollAtBottom } from "@/libs/isScrollAtBottom";
import { createLogger } from "@/libs/logger";

import "./MainPageLayout.scss";

const logger = createLogger("MainPageLayout");

type MainPageLayoutProps = {
  children: ReactNode;
  onBottomReached?: () => void;
  isPageEnd?: boolean;
  isScrollable?: boolean;
  header?: ReactNode;
  subheader?: ReactNode;
  footer?: ReactNode;
  /**
   * @deprecated please prefer using header with the dedicated PageHeader component
   */
  controlBar?: ReactNode;
  style?: CSSProperties;
  boxed?: boolean;
};

export function MainPageLayout({
  children,
  onBottomReached,
  isPageEnd = false,
  isScrollable = true,
  header,
  subheader,
  footer,
  controlBar,
  style,
  boxed = false,
}: MainPageLayoutProps) {
  const handleScroll = (
    e: React.UIEvent<HTMLDivElement> & { target: HTMLDivElement },
  ) => {
    if (!isScrollable) return;
    if (isPageEnd) return;

    const { scrollHeight, scrollTop, clientHeight: containerHeight } = e.target;

    if (
      onBottomReached &&
      isScrollAtBottom({
        scrollHeight,
        scrollTop,
        containerHeight,
      })
    ) {
      logger.info("onBottomReached");
      onBottomReached();
    }
  };

  return (
    <main className="MainPageLayout_main">
      {controlBar && (
        <div className="MainPageLayout_controlBar">{controlBar}</div>
      )}
      <div className="tw-flex tw-flex-col tw-mb-4">
        {header}
        {subheader}
      </div>
      <section
        style={style ?? {}}
        className={classnames("MainPageLayout_content", {
          "MainPageLayout_content--boxed": boxed,
        })}
        onScroll={handleScroll}
      >
        {children}
      </section>
      {footer}
    </main>
  );
}

export default MainPageLayout;
