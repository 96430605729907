import { Optimizations } from "@futurefashion/dam-api-client";
import axios from "axios";
import _ from "lodash";
import { fromPromise } from "xstate";

import apiClient from "@/blackbox/api-client";

import { Field, FieldType, RapidPipelineSchema, Tab } from "./types";

const getSchema = async () =>
  await axios
    .get("/files/rp-3d-processing-schema.json")
    .then((response) => response.data);

const buildParsedSchema = (schema: RapidPipelineSchema): Tab[] => {
  const clonedSchema = _.cloneDeep(schema);
  _.set(
    clonedSchema,
    "properties.modifier.properties",
    _.get(clonedSchema, "properties.modifier.oneOf[0].properties"),
  );
  _.set(clonedSchema, "properties.modifier.oneOf", undefined);
  const parsedSchema: Tab[] = [];

  for (const [key, value] of Object.entries(clonedSchema.properties) as [
    string,
    any,
  ][]) {
    if (PROPERTIES_TO_SKEP.includes(key)) {
      continue;
    }

    let properties = [];

    if (value.properties) {
      properties = value.properties;
    }

    if (value.oneOf) {
      properties = value.oneOf;
    }

    if (value.items) {
      properties = value.items.properties;
    }

    parsedSchema.push({
      name: value.title,
      id: value.settingid,
      description: value.description,
      fields: buildFields(key, properties),
    });
  }

  return parsedSchema;
};

const PROPERTIES_TO_SKEP = [
  "version",
  "sceneGraphFlattening",
  "import.USD",
  "import.CAD",
  "import.materials",
  "3dEdit.meshNormals",
  "3dEdit.materialEdit.alphaBlendToMaskThreshold",
  "3dEdit.materialEdit.alphaMapToOpaqueThreshold",
  "3dEdit.materialEdit.materialReplacer.defaultMaterial.generateUVs",
  "meshCulling.occlusionCulling.quality",
  "meshCulling.occlusionCulling.ignoreTransparency",
  "meshCulling.occlusionCulling.runAfterDecimator",
  "meshCulling.occlusionCulling.diffusion",
  "meshCulling.smallFeatureCulling.runAfterDecimator",
  "optimize.animationOptimization",
  //modifier field manually transformed
];

const HAS_TAB_CONTAINER = [
  "import",
  "3dEdit",
  "meshCulling",
  "optimize",
  // "modifier",
];

const buildFields = (
  parentSection: string,
  properties: Record<string, any>,
): Field[] => {
  const fields: Field[] = [];

  for (const [key, value] of Object.entries(properties)) {
    if (PROPERTIES_TO_SKEP.includes(`${parentSection}.${key}`)) {
      continue;
    }

    if (
      [
        "sizeThreshold",
        "3dModelOptimizationMethod",
        "meshAndMaterialOptimization",
        "materialReplacer",
      ].includes(key) &&
      !["dropTextures"].includes(key)
    ) {
      const childFields = value.oneOf
        .filter(
          (field: any) =>
            !["materialReplacer_dropTextures"].includes(field.settingid),
        )
        .map((field: any) => {
          return {
            name: field.title,
            id: field.settingid,
            type: "conditional",
            childFields: buildFields(
              `${parentSection}.${key}`,
              field.properties,
            ),
            accessPath: `${parentSection}.${key}`,
          };
        });
      fields.push({
        name: "tabContainerInternal",
        id: "tabContainerInternal",
        type: "tabContainerInternal",
        childFields: childFields,
      });
      continue;
    }

    let type: FieldType = "tab";

    switch (value.type) {
      case "object":
        type = "tab";
        break;
      case "boolean":
        type = "checkbox";
        break;
      case "number":
        type = "number";
        break;
      case "array":
        type = "array";
        break;
    }

    let childFields = undefined;

    if (value.properties) {
      childFields = buildFields(`${parentSection}.${key}`, value.properties);
      type = "tab";
    }

    fields.push({
      name: value.title,
      id: value.settingid,
      description: value.description,
      defaultValue: value.default,
      type,
      childFields,
      minimum: value.minimum,
      maximum: value.maximum,
      accessPath: `${parentSection}.${key}`,
    });
  }

  if (HAS_TAB_CONTAINER.includes(parentSection)) {
    return [
      {
        name: "tabContainer",
        id: "tabContainer",
        type: "tabContainer",
        childFields: fields,
      },
    ];
  }

  return fields;
};

const optimizationsClient = new Optimizations(apiClient);

export const getOptimizationDataActor = fromPromise(async () => {
  const schema = await getSchema();
  const parsedSchema = buildParsedSchema(schema);

  const {
    data: { filteredPresets },
  } = await optimizationsClient.getOptimizationsPresets().execute();

  //TODO: remove name filtering
  const presetsData = filteredPresets
    .filter((preset) => preset.name.includes("TEST_STG"))
    .map(({ config, ...rest }) => ({ ...rest, config: JSON.parse(config) }));

  return { schema, parsedSchema, presetsData };
});

export const optimizeActor = fromPromise<
  void,
  { formData: any; rawmodelId: string }
>(async ({ input }) => {
  await optimizationsClient
    .postOptimizationsRawmodelOptimize(input.rawmodelId, {
      config: input.formData,
    })
    .execute();
});
