import { useSelector } from "@xstate/react";
import { Checkbox, SwitchGroup, ZTooltip } from "@zakeke/zakeke-ui-widgets";
import classNames from "classnames";
import _ from "lodash";
import { useState } from "react";
import { RgbaColorPicker } from "react-colorful";

import { Field as FieldType } from "@/blackbox/machines/optimization/types";

import useOptimizeActor from "../hooks/useOptimizeActor";
import SelectionButton from "./SelectionButton";

const TabContainer = ({ field }: { field: FieldType }) => {
  const [activeTabId, setActiveTabId] = useState<number>(0);

  return (
    <div>
      <ul className="tw-flex tw-flex-row tw-list-none tw-my-0 tw-pl-0">
        {field.childFields?.map((field, index) => (
          <li
            onClick={() => setActiveTabId(index)}
            key={field.name}
            className={classNames(
              "tw-p-3 tw-text-sm tw-bg-gray-200 hover:tw-cursor-pointer tw-rounded-t-lg",
              {
                "tw-text-[#F46200] tw-bg-white tw-font-bold":
                  index === activeTabId,
              },
            )}
          >
            {field.name}
          </li>
        ))}
      </ul>

      <div className="tw-bg-white tw-p-3 tw-flex tw-flex-col tw-gap-2">
        <p className="tw-text-sm tw-font-bold">
          {field.childFields &&
            field.childFields[activeTabId].childFields &&
            field.childFields[activeTabId].childFields[0].type !==
              "tabContainerInternal" &&
            field.childFields[activeTabId].description}
        </p>

        {field.childFields &&
          field.childFields[activeTabId]?.childFields?.map((tabField) => {
            return <Field key={tabField.id} field={tabField} />;
          })}
      </div>
    </div>
  );
};

const TabContainerInternal = ({ field }: { field: FieldType }) => {
  const optimizeActor = useOptimizeActor();
  const [activeTabId, setActiveTabId] = useState<number>(0);

  return (
    <div>
      <div className="tw-flex tw-flex-row tw-gap-2 tw-list-none tw-my-0 tw-pl-0">
        {field.childFields
          ?.filter((field) => field.id !== "materialReplacer_defaultMaterial")
          .map((field, index) => {
            return (
              <SelectionButton
                key={field.name}
                text={field.name}
                selected={index === activeTabId}
                onClick={() => {
                  setActiveTabId(index);
                  optimizeActor.send({
                    type: "update.formData",
                    payload: {
                      accessPath: field.accessPath!,
                      value: {},
                    },
                  });
                }}
              />
            );
          })}
      </div>

      <div className="tw-bg-white tw-p-3 tw-flex tw-flex-col tw-gap-2">
        {field.childFields &&
          field.childFields[activeTabId]?.childFields?.map((tabField) => {
            return <Field key={tabField.id} field={tabField} />;
          })}
      </div>
    </div>
  );
};

const Tab: React.FC<{ field: FieldType }> = ({ field }) => {
  const optimizeActor = useOptimizeActor();
  const formSettings = useSelector(
    optimizeActor,
    (snap) => snap.context.formData,
  );

  let fieldValue;
  if (field.accessPath) {
    fieldValue = _.get(formSettings, field.accessPath);
  }

  const checked = Boolean(fieldValue);

  return (
    <>
      <SwitchGroup
        className="tw-mb-5"
        label={field.name}
        caption={field.description}
        checked={checked}
        onChange={(checked) =>
          optimizeActor.send({
            type: "update.formData",
            payload: {
              value: checked ? {} : undefined,
              accessPath: field.accessPath!,
            },
          })
        }
      />
      {field.childFields &&
        checked &&
        field.childFields.map((field) => (
          <Field key={field.id} field={field} />
        ))}
    </>
  );
};

export function Field({ field }: { field: FieldType }) {
  const optimizeActor = useOptimizeActor();
  const formSettings = useSelector(
    optimizeActor,
    (snap) => snap.context.formData,
  );

  let fieldValue;
  if (field.accessPath) {
    fieldValue = _.get(formSettings, field.accessPath);
  }

  if (field.type === "tabContainer" && field.childFields) {
    return <TabContainer field={field} />;
  }

  if (field.type === "tabContainerInternal" && field.childFields) {
    return <TabContainerInternal field={field} />;
  }

  if (field.type === "tab") {
    return <Tab field={field} />;
  }

  const selectedColor = _.get(
    formSettings,
    `["3dEdit"].materialEdit.materialReplacer.defaultMaterial.baseColor`,
  );

  return (
    <>
      {(field.id === "modifierSizeOnDisk" ||
        field.id === "generateUVsDefaultMtl") &&
        null}

      {field.type === "checkbox" && (
        <div className="tw-flex tw-flex-row tw-gap-1">
          <Checkbox
            label={<ZTooltip title={field.description}>{field.name}</ZTooltip>}
            checked={fieldValue ?? field.defaultValue}
            onChange={(checked) => {
              optimizeActor.send({
                type: "update.formData",
                payload: {
                  accessPath: field.accessPath!,
                  value: checked,
                },
              });
            }}
          />
        </div>
      )}

      {field.type === "number" &&
        field.id === "smallFeatureSizeCullingPercentage" && (
          <div className="tw-flex tw-flex-col tw-items-center">
            <div className="tw-flex tw-justify-between tw-w-full">
              <input
                className="tw-w-full tw-accent-[#F46200] tw-outline-none tw-border-none tw-ring-0 focus:tw-outline-none focus:tw-ring-0 focus-visible:tw-outline-none"
                type="range"
                onChange={(event) => {
                  optimizeActor.send({
                    type: "update.formData",
                    payload: {
                      accessPath: field.accessPath!,
                      value: Number(event.target.value),
                    },
                  });
                }}
                defaultValue={Number(field.defaultValue)}
                min={field.minimum}
                max={field.maximum}
              />
            </div>
            <div className="tw-flex tw-justify-between tw-w-full tw-text-sm tw-mt-1">
              <label className="tw-text-sm" htmlFor={field.id.replace(":", "")}>
                0
              </label>
              <label className="tw-text-sm" htmlFor={field.id.replace(":", "")}>
                50%
              </label>
              <label className="tw-text-sm" htmlFor={field.id.replace(":", "")}>
                100%
              </label>
            </div>
          </div>
        )}

      {field.type === "number" &&
        field.id !== "smallFeatureSizeCullingPercentage" && (
          <div className="tw-flex tw-flex-col tw-gap-1">
            <label
              htmlFor={field.id.replace(":", "")}
              className="tw-text-sm"
              title={field.description}
            >
              {field.name}
            </label>
            <input
              type="number"
              id={field.id.replace(":", "")}
              name={field.id.replace(":", "")}
              defaultValue={Number(field.defaultValue)}
              value={fieldValue ?? Number(field.defaultValue)}
              min={field.minimum}
              max={field.maximum}
              className="tw-w-32 tw-accent-[#F46200] tw-self-start tw-p-2.5 tw-border tw-border-slate-300 tw-border-solid tw-rounded"
              onChange={(event) => {
                optimizeActor.send({
                  type: "update.formData",
                  payload: {
                    accessPath: field.accessPath!,
                    value: Number(event.target.value),
                  },
                });
              }}
            />
            <p className="tw-mt-0 tw-mb-2">
              {String(field.description).charAt(0).toUpperCase() +
                String(field.description).slice(1)}
            </p>
          </div>
        )}

      {field.id === "material:defaultBaseColor" && (
        <div>
          <label className="tw-text-sm">{field.name}</label>

          <p className="tw-mt-0 tw-mb-2">{_.capitalize(field.description)}</p>
          <RgbaColorPicker
            color={
              selectedColor
                ? {
                    r: selectedColor[0] * 255,
                    g: selectedColor[1] * 255,
                    b: selectedColor[2] * 255,
                    a: selectedColor[3],
                  }
                : { r: 255, g: 255, b: 255, a: 1 }
            }
            onChange={(color) => {
              const r = color.r / 255;
              const g = color.g / 255;
              const b = color.b / 255;

              optimizeActor.send({
                type: "update.formData",
                payload: {
                  value: [r, g, b, color.a],
                  accessPath: field.accessPath!,
                },
              });
            }}
          />
        </div>
      )}

      {field.childFields &&
        field.childFields.map((field) => (
          <Field key={field.id} field={field} />
        ))}
    </>
  );
}
