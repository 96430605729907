import { GalleryType } from "@types";
import { rpcHttpClient } from "services";

export const getZipService = (galleryType: GalleryType) => {
  switch (galleryType) {
    case "3d":
      return rpcHttpClient.sku3dModels.createZip;
    case "gif":
      return rpcHttpClient.skus.createGifZip;
    case "video":
      return rpcHttpClient.skus.createVideoZip;
    default:
      throw new Error(`Unknown gallery type: ${galleryType}`);
  }
};

export const getZipUrl = async (skuIds: string[], galleryType: GalleryType) => {
  const service = getZipService(galleryType);
  const { url } = await service({
    skuIds,
  });

  return url;
};
