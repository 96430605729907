import {
  showErrorNotification,
  showInfoNotification,
  showSuccessNotification,
  showWarningNotification,
} from "@zakeke/zakeke-ui-widgets";

import { ToastData, ToastStatuses } from "@/@types";

export const addToast = (message: string, toastStatus: ToastStatuses) => {
  const newToast: ToastData = {
    message,
    toastStatus,
  };

  switch (newToast.toastStatus) {
    case "success":
      return showSuccessNotification({ message });
    case "info":
      return showInfoNotification({ message });
    case "warning":
      return showWarningNotification({ message });
    case "error":
      return showErrorNotification({ message });
  }
};
