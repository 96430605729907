import { Sku, Sku3dModel, SkuImage, Tag } from "@futurefashion/dam-api-client";
import { Optimizations } from "@futurefashion/dam-api-client";
import { GenericAbortSignal } from "axios";
import axios from "axios";
import { fileDownloader } from "libs/fileDownloader.ts";
import { getZipUrl } from "libs/zip.ts";
import _ from "lodash";
import { AnyActorRef, fromPromise } from "xstate";

import apiClient from "@/blackbox/api-client.ts";
import { DropZoneMediaItem } from "@/blackbox/machines/common.ts";

import {
  AllowedStatusTransitions,
  Asset3dModel,
  Filters,
  GifVideoGenerationRequest,
  SceneConfig,
  SearchItems,
  Sku3DModelUpdateFormData,
  Sku as SkuItem,
  SkuListing,
  SkuMedia,
  SkuMediaItem,
  SkusBulkPublishResults,
} from "./types.ts";

type ListingInput = {
  page: number;
  size: number;
  filters: Filters | null;
  assetsAbortSignal: GenericAbortSignal;
  availableFiltersAbortSignal: GenericAbortSignal;
};

type ListingOutput = {
  assetsData: SkuListing;
  searchItems: SearchItems;
};

const skuClient = new Sku(apiClient);
const sku3dModel = new Sku3dModel(apiClient);
const skuImages = new SkuImage(apiClient);
const tagClient = new Tag(apiClient);

export const listingActor = fromPromise<ListingOutput, ListingInput>(
  async ({ input }) => {
    const getAssetsCommand = skuClient.list({
      page: input.page,
      size: input.size,
    });
    const getAvailableFiltersCommand = skuClient.postSkusSearchItems(
      input.filters || undefined,
    );
    if (input.filters) {
      // issue with status codes types and command execution
      const filters = {
        ...input.filters,
        statusCodes: input.filters.statusCodes?.join(",") || undefined,
        types: input.filters.types?.join(",") || undefined,
        projectType: input.filters.projectType?.join(",") || undefined,
        tagIds: input.filters.tagIds?.join(",") || undefined,
      };
      //@ts-ignore
      getAssetsCommand.filter(filters);
    }

    // setting abort signals
    // @ts-ignore needed to set config to temp have abort signal
    getAssetsCommand.config = {
      //@ts-ignore
      ...getAssetsCommand.config,
      signal: input.assetsAbortSignal,
    };
    // @ts-ignore needed to set config to temp have abort signal
    getAvailableFiltersCommand.config = {
      //@ts-ignore
      ...getAvailableFiltersCommand.config,
      signal: input.availableFiltersAbortSignal,
    };

    const responses = await Promise.all([
      getAssetsCommand.execute(),
      getAvailableFiltersCommand.execute(),
    ]);

    return { assetsData: responses[0].data, searchItems: responses[1].data };
  },
);

type GetItemsInput = {
  itemsIds: Array<string>;
};

export const getItemsActor = fromPromise<SkuItem[], GetItemsInput>(
  async ({ input: { itemsIds } }) => {
    const commands = itemsIds.map((id) => skuClient.getSkus(id));
    const results = await Promise.all(commands.map((c) => c.execute()));
    return results.map((result) => result.data) as SkuItem[];
  },
);

export const gifVideoGenerationActor = fromPromise<
  number,
  GifVideoGenerationRequest
>(async ({ input }) => {
  const command = sku3dModel.postSku3dModelsGenerateGifVideo({
    ...input,
  });
  const response = await command.execute();
  return response.data.skusCount;
});

type AssignTagsInput = {
  tagsIds: Array<string>;
  skus: Array<SkuItem>;
};

export const updateTagsActor = fromPromise<void, AssignTagsInput>(
  async ({ input: { tagsIds, skus } }) => {
    const allSkusTags = skus.map((item) => item.tags);
    const initialTags = _.intersectionBy(...allSkusTags, "id");

    const tagsToAssign = tagsIds.filter(
      (tagId) => !initialTags.find((initTag) => initTag.id === tagId),
    );
    const tagsToRemove = initialTags.filter(
      (initTag) => !tagsIds.find((tagId) => initTag.id === tagId),
    );

    const assignCommands = tagsToAssign.map((tagId) =>
      tagClient.postTagsAssignTagToSkus({
        tagId,
        skuIds: skus.map((item) => item.id),
      }),
    );
    const removeCommands = tagsToRemove.map((tag) =>
      tagClient.postTagsRemoveTagFromSkus({
        tagId: tag.id,
        skuIds: skus.map((item) => item.id),
      }),
    );

    //TODO: handle responses
    await Promise.all([
      ...assignCommands.map((c) => c.execute()),
      ...removeCommands.map((c) => c.execute()),
    ]);
  },
);

type DownloadFilesInput = {
  skuIds: Array<string>;
  galleryType: "3d" | "gif" | "video";
};

export const downloadFilesActor = fromPromise<void, DownloadFilesInput>(
  async ({ input: { skuIds, galleryType } }) => {
    const url = await getZipUrl(skuIds, galleryType);
    fileDownloader(url);
  },
);

type GetSkuInput = {
  id: string;
};

const getFileAsDataUrl = async (fileUrl: string): Promise<string> => {
  const res = await fetch(fileUrl);
  const blob = await res.blob();

  const fileReaderPromise: Promise<string> = new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = () => reject(reader.error);
    reader.readAsDataURL(blob);
  });
  return await fileReaderPromise;
};

const optimizationsClient = new Optimizations(apiClient);

export const detailActor = fromPromise<
  {
    asset: SkuItem;
    models: Asset3dModel[];
    sceneConfig: Record<string, unknown> | null;
    arIcon: string | null;
    allowedStatuses: AllowedStatusTransitions;
    metrics: {
      metrics: Record<string, any> | null;
      metricsOptimized: Record<string, any> | null;
    };
  },
  GetSkuInput
>(async ({ input }) => {
  const { data: asset } = await skuClient.getSkus(input.id).execute();

  const modelsResp = await sku3dModel.list(asset.id).execute();
  const availableStatuses = await skuClient
    .postSkusStatusOptions({ id: input.id })
    .execute();
  const baseModel = modelsResp.data.result?.find(
    (model) => model.type === "MESH",
  );

  let sceneConfig = null;
  let arIcon = null;
  let metricsData = null;
  if (baseModel) {
    const configResp = await sku3dModel
      .postSku3dModelsGet3dModelFiles({ sku3dModelId: baseModel.id })
      .execute();

    sceneConfig = configResp.data.fileViewerConfig;
    const arIconUrl = sceneConfig?.uiConfig?.urlARIcon;
    if (arIconUrl) {
      arIcon = await getFileAsDataUrl(arIconUrl);
    }

    const { data } = await optimizationsClient
      .getOptimizationsModelMetrics(baseModel.id)
      .execute();
    metricsData = data;
  }

  return {
    asset: asset as SkuItem,
    models: modelsResp.data.result,
    sceneConfig,
    arIcon,
    allowedStatuses: availableStatuses.data,
    metrics: {
      metrics: metricsData?.metrics ?? null,
      metricsOptimized: metricsData?.metrics_optimized ?? null,
    },
  };
});

export const getModelActor = fromPromise<
  Asset3dModel,
  { assetId: string; modelId: string }
>(async ({ input: { assetId, modelId } }) => {
  const modelsResp = await sku3dModel
    .getSkusSku3dModels(assetId, modelId)
    .execute();
  return modelsResp.data;
});

export const updateStatusActor = fromPromise<
  { asset: SkuItem; allowedStatuses: AllowedStatusTransitions },
  { skuId: string; status: string }
>(async ({ input: { skuId, status } }) => {
  const { data } = await skuClient.patchSkus(skuId, { status }).execute();
  const { data: availableStatuses } = await skuClient
    .postSkusStatusOptions({ id: skuId })
    .execute();
  return { asset: data as SkuItem, allowedStatuses: availableStatuses };
});

export const updateDescriptionActor = fromPromise<
  SkuItem,
  { skuId: string; description: string }
>(async ({ input: { skuId, description } }) => {
  const res = await skuClient.patchSkus(skuId, { description }).execute();
  return res.data as SkuItem;
});

export const deleteSkuActor = fromPromise<void, GetSkuInput>(
  async ({ input }) => {
    await skuClient.deleteSkus(input.id).execute();
  },
);

export type Update3DModelInput = {
  skuId: string;
  modelId: string;
  formData: Sku3DModelUpdateFormData;
};

export const update3DModel = fromPromise<void, Update3DModelInput>(
  async ({ input }) => {
    const update3DModelCommand = sku3dModel.patchSkusSku3dModels(
      input.skuId,
      input.modelId,
      input.formData,
    );
    await update3DModelCommand.execute();
  },
);

export type UploadAttachmentInput = {
  skuId: string;
  file: File;
};

export const uploadAttachmentActor = fromPromise<
  SkuItem,
  UploadAttachmentInput
>(async ({ input: { skuId, file }, self }) => {
  try {
    const signedUrlRequest = await skuClient
      .patchSkus(skuId, {
        hasAttachment: false,
      })
      .execute();
    if (!signedUrlRequest.data.attachmentUrl) {
      throw new Error(
        `unexpect api error, attachmentUrl: ${signedUrlRequest.data.attachmentUrl}`,
      );
    }

    // check error, could be validation error
    await axios.put(signedUrlRequest.data.attachmentUrl, file, {
      headers: { "Content-Type": file.type },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percent = Math.floor((loaded * 100) / (total ?? 1));
        self._parent?.send({ type: "progress", payload: percent });
      },
    });

    const response = await skuClient
      .patchSkus(skuId, {
        hasAttachment: true,
      })
      .execute();

    return response.data as SkuItem;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      throw new Error(
        e.response?.data ? JSON.stringify(e.response?.data) : e.message,
      );
    }

    throw new Error(`unexpect api error: ${e}`);
  }
});

export const deleteSkuAttachmentActor = fromPromise<SkuItem, { skuId: string }>(
  async ({ input: { skuId } }) => {
    const response = await skuClient
      .patchSkus(skuId, {
        hasAttachment: false,
      })
      .execute();

    return response.data as SkuItem;
  },
);

interface UpdateSceneConfigInput {
  sceneConfig: SceneConfig;
  arIcon: string | null;
  modelId: string;
}

export const updateSceneConfig = fromPromise<void, UpdateSceneConfigInput>(
  async ({ input: { sceneConfig, arIcon, modelId } }) => {
    const command = sku3dModel.postSku3dModelsUpdateViewerConfig({
      sku3dModelId: modelId,
      viewerConfigFileName: "babylon-customization.json",
      viewerConfig: sceneConfig,
      arIcon: arIcon || undefined,
    });

    await command.execute();
  },
);

export const getSkuImagesActor = fromPromise<SkuMedia, string>(
  async ({ input: skuId }) => {
    const resp = await skuImages.getSkusSkuImages(skuId).execute();

    return resp.data.result;
  },
);

const itemMediaUpload = async (
  skuId: string,
  media: DropZoneMediaItem,
  parent?: AnyActorRef,
): Promise<SkuMediaItem> => {
  const { data: itemCreateResp } = await skuImages
    .postSkusSkuImages(skuId, { fileName: media.name })
    .execute();

  if (!itemCreateResp.url) {
    throw new Error("error creating asset media");
  }

  await axios.put(itemCreateResp.url, media.file, {
    headers: { "Content-Type": media.file.type },
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      const percent = Math.floor((loaded * 100) / (total ?? 1));
      parent?.send({
        type: "media.progress",
        payload: { id: media.id, progress: percent },
      });
    },
  });

  const patchResp = await skuImages
    .patchSkusSkuImages(itemCreateResp.id, skuId, { uploaded: true })
    .execute();

  return patchResp.data;
};

// This is already the second time that we copied this function almost identically, next time create a common functinality
export const uploadMediaActor = fromPromise<
  SkuMedia,
  { skuId: string; files: DropZoneMediaItem[] }
>(async ({ input: { skuId, files }, self }) => {
  const results = await Promise.allSettled(
    files.map((file) => itemMediaUpload(skuId, file, self._parent)),
  );

  files.forEach((media) =>
    self._parent?.send({
      type: "progress",
      payload: { id: media.id, progress: 100 },
    }),
  );

  return (
    results
      .filter(({ status }) => status === "fulfilled")
      // @ts-ignore ts does not understand we already filtered by status
      .map((result) => result.value)
  );
});

export const deleteMediaActor = fromPromise<
  string,
  { skuId: string; mediaId: string }
>(async ({ input: { skuId, mediaId } }) => {
  await skuImages.deleteSkusSkuImages(mediaId, skuId).execute();
  return mediaId;
});

export const createZipActor = fromPromise<string, string>(
  async ({ input: skuId }) => {
    const resp = await skuImages
      .postSkuImagesCreateZip({ skuIds: [skuId] })
      .execute();

    return resp.data.url;
  },
);

export const setImageAsMainActor = fromPromise<
  SkuMediaItem,
  { imageId: string; assetId: string }
>(async ({ input: { imageId, assetId } }) => {
  const resp = await skuImages
    .patchSkusSkuImages(imageId, assetId, { main: true })
    .execute();

  return resp.data;
});

export type UploadNewModelInput = {
  skuId: string;
  modelId: string | null;
  description?: string;
  model?: File | null;
  env?: File | null;
  preview?: File | null;
  type?: "MESH" | "MATERIAL";
};

export const uploadNewModelActor = fromPromise<
  Asset3dModel,
  UploadNewModelInput
>(
  async ({
    input: { skuId, modelId, description, model, env, preview, type },
    self,
  }) => {
    let assetFile: Asset3dModel;
    if (!modelId) {
      const { data } = await sku3dModel
        .postSkusSku3dModels(skuId, {
          description: description,
          type: type ?? "MESH",
          file3dName: model!.name,
          fileEnvironmentName: env ? env.name : undefined,
        })
        .execute();
      assetFile = data as Asset3dModel;
    } else {
      const { data } = await sku3dModel
        .patchSkusSku3dModels(skuId, modelId, {
          description: description,
          hasFile3d: model ? false : undefined,
          hasFileEnvironment: env !== undefined ? false : undefined,
          hasFilePreview: preview !== undefined ? false : undefined,
          fileEnvironmentName: env ? env.name : undefined,
        })
        .execute();
      assetFile = data as Asset3dModel;
    }

    const modelPromise = [];
    if (model) {
      modelPromise.push(
        axios.put(assetFile.file3dUrl!, model, {
          headers: { "Content-Type": model.type },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            self?._parent?.send({
              type: "uploading.progress",
              payload: { model: Math.floor((loaded * 100) / (total ?? 1)) },
            });
          },
        }),
      );
    }

    if (env) {
      modelPromise.push(
        axios.put(assetFile.fileEnvironmentUrl!, env, {
          headers: { "Content-Type": env.type },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            self?._parent?.send({
              type: "uploading.progress",
              payload: { env: Math.floor((loaded * 100) / (total ?? 1)) },
            });
          },
        }),
      );
    }

    if (preview) {
      modelPromise.push(
        axios.put(assetFile.filePreviewUrl!, preview, {
          headers: { "Content-Type": preview.type },
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            self?._parent?.send({
              type: "uploading.progress",
              payload: { env: Math.floor((loaded * 100) / (total ?? 1)) },
            });
          },
        }),
      );
    }

    if (modelPromise.length === 0) {
      const { data } = await sku3dModel
        .getSkusSku3dModels(skuId, assetFile.id)
        .execute();

      return data;
    }
    await Promise.all(modelPromise);

    await sku3dModel
      .patchSkusSku3dModels(skuId, assetFile.id, {
        hasFile3d: true,
        hasFileEnvironment: env ? true : undefined,
        hasFilePreview: preview ? true : undefined,
      })
      .execute();

    const { data } = await sku3dModel
      .getSkusSku3dModels(skuId, assetFile.id)
      .execute();

    return data;
  },
);

export const publishAsset = fromPromise<undefined, { assetId: string }>(
  async ({ input: { assetId } }) => {
    await skuClient.patchSkusPublish(assetId).execute();
  },
);

export const unpublishAsset = fromPromise<undefined, { assetId: string }>(
  async ({ input: { assetId } }) => {
    await skuClient.patchSkusUnpublish(assetId).execute();
  },
);

export const bulkUnpublishAssets = fromPromise<
  SkusBulkPublishResults,
  { assetsIds: string[] }
>(async ({ input: { assetsIds } }) => {
  const resp = await skuClient
    .patchSkusBulkUnpublish({
      skuIds: assetsIds,
    })
    .execute();

  return resp.data.results;
});
