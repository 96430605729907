import { useSelector } from "@xstate/react";
import { Checkbox, ZSwitch, ZTooltip } from "@zakeke/zakeke-ui-widgets";
import { Select } from "@zakeke/zakeke-ui-widgets";
import classNames from "classnames";
import _ from "lodash";

import useOptimizeActor from "../hooks/useOptimizeActor";
import SelectionButton from "./SelectionButton";

export function OptimizeTab({
  tab,
  materialOptimizationRef,
}: {
  tab: Record<string, any>;
  materialOptimizationRef: Record<string, any>;
}) {
  const optimizeActor = useOptimizeActor();
  const optimizationMethod = useSelector(
    optimizeActor,
    (snap) => snap.context.formData?.optimize?.["3dModelOptimizationMethod"],
  );

  const updateForm = (payload: { accessPath: string; value: any }) => {
    optimizeActor.send({
      type: "update.formData",
      payload,
    });
  };

  return (
    <div className="tw-bg-gray-50 tw-p-8">
      <h4 className="tw-my-0">{tab.title}</h4>
      <p>{tab.description}</p>

      <ul className="tw-flex tw-flex-row tw-list-none tw-my-0 tw-pl-0">
        <li
          className={classNames(
            "tw-flex tw-justify-center tw-p-3 tw-text-sm tw-bg-gray-200 hover:tw-cursor-pointer tw-rounded-t-lg",
            {
              "tw-bg-white": Boolean(optimizationMethod) === true,
            },
          )}
        >
          <ZSwitch
            id="switch"
            onChange={(checked) => {
              if (checked) {
                updateForm({
                  accessPath: "optimize.3dModelOptimizationMethod",
                  value: {
                    meshAndMaterialOptimization: {
                      decimator: {
                        materialOptimization: {
                          keepMaterialsUVs: {},
                        },
                      },
                    },
                  },
                });
              } else {
                updateForm({
                  accessPath: "optimize.3dModelOptimizationMethod",
                  value: undefined,
                });
              }
            }}
            checked={optimizationMethod}
          />
          <span className="tw-pl-1">
            {tab.properties["3dModelOptimizationMethod"].title}
          </span>
        </li>
      </ul>

      <div className="tw-bg-white tw-p-3 tw-flex tw-flex-col tw-gap-2">
        {optimizationMethod && (
          <>
            <p className="tw-text-sm tw-ml-11 tw-my-0">
              {tab.properties["3dModelOptimizationMethod"].description}
            </p>
            <div>
              <div className="tw-flex tw-flex-row tw-gap-2 tw-list-none tw-my-0 tw-ml-11 tw-pl-0">
                <SelectionButton
                  text={
                    tab.properties["3dModelOptimizationMethod"].oneOf[0].title
                  }
                  selected={optimizationMethod?.meshAndMaterialOptimization}
                  onClick={() =>
                    updateForm({
                      accessPath: "optimize.3dModelOptimizationMethod",
                      value: {
                        meshAndMaterialOptimization: {
                          decimator: {
                            materialOptimization: {
                              keepMaterialsUVs: {},
                            },
                          },
                        },
                      },
                    })
                  }
                />
                <SelectionButton
                  text={
                    tab.properties["3dModelOptimizationMethod"].oneOf[1].title
                  }
                  selected={optimizationMethod?.onlyMaterial}
                  onClick={() =>
                    updateForm({
                      accessPath: "optimize.3dModelOptimizationMethod",
                      value: {
                        onlyMaterial: {
                          keepMaterialsUVs: {},
                        },
                      },
                    })
                  }
                />
              </div>

              {optimizationMethod?.meshAndMaterialOptimization && (
                <div>
                  <p className="tw-text-sm">
                    {
                      tab.properties["3dModelOptimizationMethod"].oneOf[0]
                        .description
                    }
                  </p>

                  <div className="tw-flex tw-flex-row tw-gap-2 tw-list-none tw-my-0 tw-pl-0">
                    <SelectionButton
                      text={
                        tab.properties["3dModelOptimizationMethod"].oneOf[0]
                          .properties.meshAndMaterialOptimization.oneOf[0].title
                      }
                      selected={
                        optimizationMethod?.meshAndMaterialOptimization
                          ?.decimator
                      }
                      onClick={() =>
                        updateForm({
                          accessPath:
                            "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization",
                          value: {
                            decimator: {
                              materialOptimization: {
                                keepMaterialsUVs: {},
                              },
                            },
                          },
                        })
                      }
                    />
                    <SelectionButton
                      text={
                        tab.properties["3dModelOptimizationMethod"].oneOf[0]
                          .properties.meshAndMaterialOptimization.oneOf[1].title
                      }
                      selected={
                        optimizationMethod?.meshAndMaterialOptimization
                          ?.remesher
                      }
                      onClick={() =>
                        updateForm({
                          accessPath:
                            "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization",
                          value: {
                            remesher: {
                              target: {},
                              materialMerger: {
                                materialRegenerator: {
                                  uvAtlasGenerator: {
                                    textureBaker: {},
                                  },
                                },
                              },
                            },
                          },
                        })
                      }
                    />
                  </div>

                  {optimizationMethod?.meshAndMaterialOptimization
                    ?.decimator && (
                    <div>
                      <p className="tw-text-sm">
                        {
                          tab.properties["3dModelOptimizationMethod"].oneOf[0]
                            .properties.meshAndMaterialOptimization.oneOf[0]
                            .description
                        }
                      </p>

                      <div className="tw-flex tw-flex-row tw-gap-1">
                        <Checkbox
                          label={
                            <ZTooltip
                              title={
                                tab.properties["3dModelOptimizationMethod"]
                                  .oneOf[0].properties
                                  .meshAndMaterialOptimization.oneOf[0]
                                  .properties.decimator.properties
                                  .preserveTopology.description
                              }
                            >
                              {
                                tab.properties["3dModelOptimizationMethod"]
                                  .oneOf[0].properties
                                  .meshAndMaterialOptimization.oneOf[0]
                                  .properties.decimator.properties
                                  .preserveTopology.title
                              }
                            </ZTooltip>
                          }
                          checked={_.get(
                            optimizationMethod,
                            "meshAndMaterialOptimization.decimator.preserveTopology",
                          )}
                          onChange={(checked) =>
                            updateForm({
                              accessPath:
                                "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.preserveTopology",
                              value: checked,
                            })
                          }
                        />
                      </div>
                      <div className="tw-flex tw-flex-row tw-gap-1">
                        <Checkbox
                          label={
                            <ZTooltip
                              title={
                                tab.properties["3dModelOptimizationMethod"]
                                  .oneOf[0].properties
                                  .meshAndMaterialOptimization.oneOf[0]
                                  .properties.decimator.properties
                                  .preserveNormals.description
                              }
                            >
                              {
                                tab.properties["3dModelOptimizationMethod"]
                                  .oneOf[0].properties
                                  .meshAndMaterialOptimization.oneOf[0]
                                  .properties.decimator.properties
                                  .preserveNormals.title
                              }
                            </ZTooltip>
                          }
                          checked={_.get(
                            optimizationMethod,
                            "meshAndMaterialOptimization.decimator.preserveNormals",
                          )}
                          onChange={(checked) =>
                            updateForm({
                              accessPath:
                                "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.preserveNormals",
                              value: checked,
                            })
                          }
                        />
                      </div>
                      <div className="tw-flex tw-flex-col tw-gap-1 tw-my-4">
                        <h5 className="tw-my-0">
                          {materialOptimizationRef.title}
                        </h5>
                        <p className="tw-text-sm tw-mt-0">
                          {materialOptimizationRef.description}
                        </p>

                        <Select
                          className="tw-max-w-lg"
                          onChange={(value) => {
                            if (
                              value === "materialOptimization_materialMerger"
                            ) {
                              updateForm({
                                accessPath:
                                  "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.materialOptimization",
                                value: {
                                  materialMerger: {
                                    materialRegenerator: {
                                      uvAtlasGenerator: {
                                        textureBaker: {},
                                      },
                                    },
                                  },
                                },
                              });
                            } else if (
                              value ===
                              "materialOptimization_materialUVAggregator"
                            ) {
                              updateForm({
                                accessPath:
                                  "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.materialOptimization",
                                value: {
                                  materialUVAggregator: {},
                                },
                              });
                            } else {
                              updateForm({
                                accessPath:
                                  "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.materialOptimization",
                                value: {
                                  keepMaterialsUVs: {},
                                },
                              });
                            }
                          }}
                          value={
                            _.get(
                              optimizationMethod,
                              "meshAndMaterialOptimization.decimator.materialOptimization.materialMerger",
                            )
                              ? "materialOptimization_materialMerger"
                              : _.get(
                                    optimizationMethod,
                                    "meshAndMaterialOptimization.decimator.materialOptimization.materialUVAggregator",
                                  )
                                ? "materialOptimization_materialUVAggregator"
                                : "materialOptimization_keepMaterialsUVs"
                          }
                        >
                          {materialOptimizationRef.oneOf.map(
                            (item: {
                              key: string;
                              settingid: string;
                              title: string;
                            }) => (
                              <option
                                key={item.settingid}
                                value={item.settingid}
                              >
                                {item.title}
                              </option>
                            ),
                          )}
                        </Select>
                      </div>
                      <div className="tw-flex tw-flex-col tw-gap-1">
                        <Checkbox
                          checked={_.get(
                            optimizationMethod,
                            "meshAndMaterialOptimization.decimator.target.faces",
                          )}
                          onChange={(checked) => {
                            let value = undefined;
                            if (checked) {
                              value = {
                                faces: {
                                  percentage: 100,
                                },
                              };
                            } else {
                              value = undefined;
                            }
                            updateForm({
                              accessPath:
                                "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.target",
                              value,
                            });
                          }}
                          label={
                            tab.properties["3dModelOptimizationMethod"].oneOf[0]
                              .properties.meshAndMaterialOptimization.oneOf[0]
                              .properties.decimator.properties.target.properties
                              .faces.title
                          }
                          caption="Face Targets for the Decimation Operation"
                        />

                        <div className="tw-basis-full tw-flex tw-flex-row tw-gap-2 tw-list-none tw-my-0 tw-ml-7 tw-pl-0 tw-mt-1">
                          <SelectionButton
                            text={
                              tab.properties["3dModelOptimizationMethod"]
                                .oneOf[0].properties.meshAndMaterialOptimization
                                .oneOf[0].properties.decimator.properties.target
                                .properties.faces.oneOf[0].title
                            }
                            selected={
                              _.get(
                                optimizationMethod,
                                "meshAndMaterialOptimization.decimator.target.faces.percentage",
                              ) !== undefined
                            }
                            onClick={() =>
                              updateForm({
                                accessPath:
                                  "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.target.faces",
                                value: {
                                  percentage: 100,
                                },
                              })
                            }
                          />
                          <SelectionButton
                            text={
                              tab.properties["3dModelOptimizationMethod"]
                                .oneOf[0].properties.meshAndMaterialOptimization
                                .oneOf[0].properties.decimator.properties.target
                                .properties.faces.oneOf[1].title
                            }
                            selected={
                              _.get(
                                optimizationMethod,
                                "meshAndMaterialOptimization.decimator.target.faces.value",
                              ) !== undefined
                            }
                            onClick={() =>
                              updateForm({
                                accessPath:
                                  "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.target.faces",
                                value: {
                                  value: 1,
                                },
                              })
                            }
                          />
                        </div>

                        {_.get(
                          optimizationMethod,
                          "meshAndMaterialOptimization.decimator.target.faces.percentage",
                        ) !== undefined && (
                          <div>
                            <p>
                              {
                                tab.properties["3dModelOptimizationMethod"]
                                  .oneOf[0].properties
                                  .meshAndMaterialOptimization.oneOf[0]
                                  .properties.decimator.properties.target
                                  .properties.faces.oneOf[0].description
                              }
                            </p>
                            <div className="tw-flex tw-flex-col tw-items-center">
                              <div className="tw-flex tw-justify-between tw-w-full">
                                <input
                                  className="tw-w-full tw-accent-[#F46200] tw-outline-none tw-border-none tw-ring-0 focus:tw-outline-none focus:tw-ring-0 focus-visible:tw-outline-none"
                                  type="range"
                                  onChange={(event) =>
                                    updateForm({
                                      accessPath:
                                        "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.target.faces.percentage",
                                      value: Number(event.target.value),
                                    })
                                  }
                                  defaultValue={
                                    tab.properties["3dModelOptimizationMethod"]
                                      .oneOf[0].properties
                                      .meshAndMaterialOptimization.oneOf[0]
                                      .properties.decimator.properties.target
                                      .properties.faces.oneOf[0].properties
                                      .percentage.default
                                  }
                                  min={
                                    tab.properties["3dModelOptimizationMethod"]
                                      .oneOf[0].properties
                                      .meshAndMaterialOptimization.oneOf[0]
                                      .properties.decimator.properties.target
                                      .properties.faces.oneOf[0].properties
                                      .percentage.minimum
                                  }
                                  max={
                                    tab.properties["3dModelOptimizationMethod"]
                                      .oneOf[0].properties
                                      .meshAndMaterialOptimization.oneOf[0]
                                      .properties.decimator.properties.target
                                      .properties.faces.oneOf[0].properties
                                      .percentage.maximum
                                  }
                                />
                              </div>
                              <div className="tw-flex tw-justify-between tw-w-full tw-text-sm tw-mt-1">
                                <label
                                  className="tw-text-sm"
                                  htmlFor={
                                    tab.properties["3dModelOptimizationMethod"]
                                      .oneOf[0].properties
                                      .meshAndMaterialOptimization.oneOf[0]
                                      .properties.decimator.properties.target
                                      .properties.faces.oneOf[0].properties
                                      .percentage.settingid
                                  }
                                >
                                  0
                                </label>
                                <label
                                  className="tw-text-sm"
                                  htmlFor={
                                    tab.properties["3dModelOptimizationMethod"]
                                      .oneOf[0].properties
                                      .meshAndMaterialOptimization.oneOf[0]
                                      .properties.decimator.properties.target
                                      .properties.faces.oneOf[0].properties
                                      .percentage.settingid
                                  }
                                >
                                  50%
                                </label>
                                <label
                                  className="tw-text-sm"
                                  htmlFor={
                                    tab.properties["3dModelOptimizationMethod"]
                                      .oneOf[0].properties
                                      .meshAndMaterialOptimization.oneOf[0]
                                      .properties.decimator.properties.target
                                      .properties.faces.oneOf[0].properties
                                      .percentage.settingid
                                  }
                                >
                                  100%
                                </label>
                              </div>
                            </div>
                          </div>
                        )}

                        {_.get(
                          optimizationMethod,
                          "meshAndMaterialOptimization.decimator.target.faces.value",
                        ) !== undefined && (
                          <div>
                            <div>
                              <p>
                                {
                                  tab.properties["3dModelOptimizationMethod"]
                                    .oneOf[0].properties
                                    .meshAndMaterialOptimization.oneOf[0]
                                    .properties.decimator.properties.target
                                    .properties.faces.oneOf[1].description
                                }
                              </p>

                              <input
                                type="number"
                                onChange={(event) =>
                                  updateForm({
                                    accessPath:
                                      "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.decimator.target.faces.value",
                                    value: Number(event.target.value),
                                  })
                                }
                                className="tw-w-32 tw-accent-[#F46200] tw-self-start tw-p-2.5 tw-border tw-border-slate-300 tw-border-solid tw-rounded"
                                defaultValue={
                                  tab.properties["3dModelOptimizationMethod"]
                                    .oneOf[0].properties
                                    .meshAndMaterialOptimization.oneOf[0]
                                    .properties.decimator.properties.target
                                    .properties.faces.oneOf[1].properties.value
                                    .default
                                }
                                min={
                                  tab.properties["3dModelOptimizationMethod"]
                                    .oneOf[0].properties
                                    .meshAndMaterialOptimization.oneOf[0]
                                    .properties.decimator.properties.target
                                    .properties.faces.oneOf[1].properties.value
                                    .minimum
                                }
                                max={
                                  tab.properties["3dModelOptimizationMethod"]
                                    .oneOf[0].properties
                                    .meshAndMaterialOptimization.oneOf[0]
                                    .properties.decimator.properties.target
                                    .properties.faces.oneOf[1].properties.value
                                    .maximum
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {optimizationMethod?.meshAndMaterialOptimization
                    ?.remesher && (
                    <div>
                      <p className="tw-text-sm">
                        {
                          tab.properties["3dModelOptimizationMethod"].oneOf[0]
                            .properties.meshAndMaterialOptimization.oneOf[1]
                            .description
                        }
                      </p>

                      <Select
                        name="SelectRemeshingMethod"
                        onChange={(value) =>
                          updateForm({
                            accessPath:
                              "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.remesher.method",
                            value,
                          })
                        }
                        value={_.get(
                          optimizationMethod,
                          "meshAndMaterialOptimization.remesher.method",
                        )}
                      >
                        {tab.properties[
                          "3dModelOptimizationMethod"
                        ].oneOf[0].properties.meshAndMaterialOptimization.oneOf[1].properties.remesher.properties.method.enum.map(
                          (item: "voxelization" | "shrinkwrap") => (
                            <option key={item} value={item}>
                              {item}
                            </option>
                          ),
                        )}
                      </Select>

                      <div className="tw-flex tw-flex-row tw-flex-wrap tw-gap-1 tw-mt-1">
                        <Checkbox
                          checked={_.get(
                            optimizationMethod,
                            "meshAndMaterialOptimization.remesher.target.faces",
                          )}
                          onChange={(checked) => {
                            let value = undefined;
                            if (checked) {
                              value = {
                                faces: {
                                  percentage: 100,
                                },
                              };
                            } else {
                              value = undefined;
                            }
                            updateForm({
                              accessPath:
                                "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.remesher.target",
                              value,
                            });
                          }}
                          label={
                            tab.properties["3dModelOptimizationMethod"].oneOf[0]
                              .properties.meshAndMaterialOptimization.oneOf[1]
                              .properties.remesher.properties.target.properties
                              .faces.title
                          }
                          caption={
                            tab.properties["3dModelOptimizationMethod"].oneOf[0]
                              .properties.meshAndMaterialOptimization.oneOf[1]
                              .properties.remesher.properties.target.properties
                              .faces.description
                          }
                        />
                      </div>

                      <div className="tw-basis-full tw-flex tw-flex-row tw-gap-2 tw-list-none tw-my-0 tw-ml-7 tw-pl-0 tw-mt-1">
                        <SelectionButton
                          text={
                            tab.properties["3dModelOptimizationMethod"].oneOf[0]
                              .properties.meshAndMaterialOptimization.oneOf[1]
                              .properties.remesher.properties.target.properties
                              .faces.oneOf[0].title
                          }
                          selected={
                            _.get(
                              optimizationMethod,
                              "meshAndMaterialOptimization.remesher.target.faces.percentage",
                            ) !== undefined
                          }
                          onClick={() =>
                            updateForm({
                              accessPath:
                                "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.remesher.target.faces",
                              value: {
                                percentage: 100,
                              },
                            })
                          }
                        />
                        <SelectionButton
                          text={
                            tab.properties["3dModelOptimizationMethod"].oneOf[0]
                              .properties.meshAndMaterialOptimization.oneOf[1]
                              .properties.remesher.properties.target.properties
                              .faces.oneOf[1].title
                          }
                          selected={
                            _.get(
                              optimizationMethod,
                              "meshAndMaterialOptimization.remesher.target.faces.value",
                            ) !== undefined
                          }
                          onClick={() =>
                            updateForm({
                              accessPath:
                                "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.remesher.target.faces",
                              value: {
                                value: 1,
                              },
                            })
                          }
                        />
                      </div>

                      {_.get(
                        optimizationMethod,
                        "meshAndMaterialOptimization.remesher.target.faces.percentage",
                      ) !== undefined && (
                        <div>
                          <p>
                            {
                              tab.properties["3dModelOptimizationMethod"]
                                .oneOf[0].properties.meshAndMaterialOptimization
                                .oneOf[1].properties.remesher.properties.target
                                .properties.faces.oneOf[0].description
                            }
                          </p>
                          <div className="tw-flex tw-flex-col tw-items-center">
                            <div className="tw-flex tw-justify-between tw-w-full">
                              <input
                                className="tw-w-full tw-accent-[#F46200] tw-outline-none tw-border-none tw-ring-0 focus:tw-outline-none focus:tw-ring-0 focus-visible:tw-outline-none"
                                type="range"
                                onChange={(e) =>
                                  updateForm({
                                    accessPath:
                                      "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.remesher.target.faces.percentage",
                                    value: Number(e.target.value),
                                  })
                                }
                                defaultValue={
                                  tab.properties["3dModelOptimizationMethod"]
                                    .oneOf[0].properties
                                    .meshAndMaterialOptimization.oneOf[1]
                                    .properties.remesher.properties.target
                                    .properties.faces.oneOf[0].properties
                                    .percentage.default
                                }
                                min={
                                  tab.properties["3dModelOptimizationMethod"]
                                    .oneOf[0].properties
                                    .meshAndMaterialOptimization.oneOf[1]
                                    .properties.remesher.properties.target
                                    .properties.faces.oneOf[0].properties
                                    .percentage.minimum
                                }
                                max={
                                  tab.properties["3dModelOptimizationMethod"]
                                    .oneOf[0].properties
                                    .meshAndMaterialOptimization.oneOf[1]
                                    .properties.remesher.properties.target
                                    .properties.faces.oneOf[0].properties
                                    .percentage.maximum
                                }
                              />
                            </div>
                            <div className="tw-flex tw-justify-between tw-w-full tw-text-sm tw-mt-1">
                              <label
                                className="tw-text-sm"
                                htmlFor={
                                  tab.properties["3dModelOptimizationMethod"]
                                    .oneOf[0].properties
                                    .meshAndMaterialOptimization.oneOf[1]
                                    .properties.remesher.properties.target
                                    .properties.faces.oneOf[0].properties
                                    .percentage.settingid
                                }
                              >
                                0
                              </label>
                              <label
                                className="tw-text-sm"
                                htmlFor={
                                  tab.properties["3dModelOptimizationMethod"]
                                    .oneOf[0].properties
                                    .meshAndMaterialOptimization.oneOf[1]
                                    .properties.remesher.properties.target
                                    .properties.faces.oneOf[0].properties
                                    .percentage.settingid
                                }
                              >
                                50%
                              </label>
                              <label
                                className="tw-text-sm"
                                htmlFor={
                                  tab.properties["3dModelOptimizationMethod"]
                                    .oneOf[0].properties
                                    .meshAndMaterialOptimization.oneOf[1]
                                    .properties.remesher.properties.target
                                    .properties.faces.oneOf[0].properties
                                    .percentage.settingid
                                }
                              >
                                100%
                              </label>
                            </div>
                          </div>
                        </div>
                      )}

                      {_.get(
                        optimizationMethod,
                        "meshAndMaterialOptimization.remesher.target.faces.value",
                      ) !== undefined && (
                        <div>
                          <div>
                            <p>
                              {
                                tab.properties["3dModelOptimizationMethod"]
                                  .oneOf[0].properties
                                  .meshAndMaterialOptimization.oneOf[1]
                                  .properties.remesher.properties.target
                                  .properties.faces.oneOf[1].description
                              }
                            </p>

                            <input
                              type="number"
                              className="tw-w-32 tw-accent-[#F46200] tw-self-start tw-p-2.5 tw-border tw-border-slate-300 tw-border-solid tw-rounded"
                              onChange={(event) =>
                                updateForm({
                                  accessPath:
                                    "optimize.3dModelOptimizationMethod.meshAndMaterialOptimization.remesher.target.faces.value",
                                  value: Number(event.target.value),
                                })
                              }
                              name={
                                tab.properties["3dModelOptimizationMethod"]
                                  .oneOf[0].properties
                                  .meshAndMaterialOptimization.oneOf[1]
                                  .properties.remesher.properties.target
                                  .properties.faces.oneOf[1].properties.value
                                  .settingid
                              }
                              defaultValue={
                                tab.properties["3dModelOptimizationMethod"]
                                  .oneOf[0].properties
                                  .meshAndMaterialOptimization.oneOf[1]
                                  .properties.remesher.properties.target
                                  .properties.faces.oneOf[1].properties.value
                                  .default
                              }
                              min={
                                tab.properties["3dModelOptimizationMethod"]
                                  .oneOf[0].properties
                                  .meshAndMaterialOptimization.oneOf[1]
                                  .properties.remesher.properties.target
                                  .properties.faces.oneOf[1].properties.value
                                  .minimum
                              }
                              max={
                                tab.properties["3dModelOptimizationMethod"]
                                  .oneOf[0].properties
                                  .meshAndMaterialOptimization.oneOf[1]
                                  .properties.remesher.properties.target
                                  .properties.faces.oneOf[1].properties.value
                                  .maximum
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}

              {optimizationMethod?.onlyMaterial && (
                <div>
                  <p className="tw-text-sm">
                    {materialOptimizationRef.description}
                  </p>
                  <Select
                    onChange={(value) => {
                      if (value === "materialOptimization_materialMerger") {
                        updateForm({
                          accessPath:
                            "optimize.3dModelOptimizationMethod.onlyMaterial",
                          value: {
                            materialMerger: {
                              materialRegenerator: {
                                uvAtlasGenerator: {
                                  textureBaker: {},
                                },
                              },
                            },
                          },
                        });
                      } else if (
                        value === "materialOptimization_materialUVAggregator"
                      ) {
                        updateForm({
                          accessPath:
                            "optimize.3dModelOptimizationMethod.onlyMaterial",
                          value: {
                            materialUVAggregator: {},
                          },
                        });
                      } else {
                        updateForm({
                          accessPath:
                            "optimize.3dModelOptimizationMethod.onlyMaterial",
                          value: {
                            keepMaterialsUVs: {},
                          },
                        });
                      }
                    }}
                    value={
                      _.get(optimizationMethod, "onlyMaterial.materialMerger")
                        ? "materialOptimization_materialMerger"
                        : _.get(
                              optimizationMethod,
                              "onlyMaterial.materialUVAggregator",
                            )
                          ? "materialOptimization_materialUVAggregator"
                          : "materialOptimization_keepMaterialsUVs"
                    }
                  >
                    {materialOptimizationRef.oneOf.map(
                      (item: {
                        key: string;
                        settingid: string;
                        title: string;
                      }) => (
                        <option key={item.settingid} value={item.settingid}>
                          {item.title}
                        </option>
                      ),
                    )}
                  </Select>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
