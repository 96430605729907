/* eslint-disable prettier/prettier */
import { useMachine } from "@xstate/react";
import SpinnerWrapper from "components/SpinnerWrapper";
import { addToast } from "libs/toaster";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import optimizeMachine from "@/blackbox/machines/optimization/optimize";
import PageHeader from "@/components/PageHeader";
import { OptimizeContext } from "@/contexts/Optimize";
import { MainPageLayout } from "@/layouts";
import king from "@/svg/king.svg?plain";
import Template from "@/templates/Optimize/Template.tsx";

export default function Optimize() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [state, , optimizeActor] = useMachine(
    optimizeMachine.provide({
      actions: {
        onOptimizeSuccess: () => {
          addToast("Asset optimization succesfully started", "success");
          navigate(`/assets/${id}`);
        },
        onOptimizeFail: (error: unknown) => {
          //@ts-ignore
          addToast(
            `Asset optimization fails: ${error?.message ?? "Unkown error"}`,
            "error",
          );
        },
      },
    }),
    {
      input: {
        assetId: id!,
      },
    },
  );

  const error = state.context.error;

  useEffect(() => {
    if (error) {
      //@ts-ignore
      addToast(
        `Asset optimization fails: ${error?.message ?? "Unkown error"}`,
        "error",
      );
    }
  }, [error]);

  return (
    <OptimizeContext.Provider value={optimizeActor}>
      <MainPageLayout
        header={
          <PageHeader
            title={"3D Asset Optimization"}
            subtitle={
              "Optimization settings automatically reduce the 3D model’s file size, polygon count, vertex count, and texture size, ensuring seamless real-time rendering and augmented reality compatibility, without compromising visual quality."
            }
            className={"tw-pb-2"}
          ></PageHeader>
        }
        subheader={
          <div className="tw-bg-[#F0047F1A] tw-rounded-lg tw-grid tw-grid-cols-[auto_auto_1fr] tw-justify-center tw-mt-2">
            <p className="tw-bg-[#F0047F] tw-m-0 tw-px-1 tw-py-1 tw-flex tw-items-center tw-rounded-l-lg">
              <img src={king} alt="" className="icon" />
            </p>
            <p className="tw-text-xs tw-px-4 tw-py-1">
              <span className="tw-font-bold">Remember</span>: This feature is
              free for the first five optimizations. After that, a charge fee of
              0.60$ will apply to each optimization.
            </p>
            <a
              className="tw-px-4 tw-py-1 tw-text-xs tw-text-[#F0047F] tw-font-bold tw-flex tw-items-center tw-justify-self-end visited:tw-text-[#F0047F]"
              href="https://zakeke.zendesk.com/hc/en-us/articles/18186651154972-3D-Asset-Optimization"
              target="_blank"
            >
              Learn more
            </a>
          </div>
        }
        style={{ backgroundColor: "#fff" }}
      >
        <SpinnerWrapper
          spinning={
            // @ts-ignore
            state.matches("mounting") ||
            // @ts-ignore
            state.matches("changing")
          }
        >
          <Template />
        </SpinnerWrapper>
      </MainPageLayout>
    </OptimizeContext.Provider>
  );
}
